import * as yup from 'yup';
import ValidationMessage from '~/helpers/validator/message';
import { createValidator as createGroupValidator } from './packingListGroup';
import { amountInnerRule } from './specSheet';

export const imageValidator = yup.object({
    id: yup.string().required(ValidationMessage.required()),
    url: yup.string().required(ValidationMessage.required()),
});

const packingListAmountValidator = {
    id: yup.string().required(ValidationMessage.required()),
    name: yup.string().required(ValidationMessage.required()),
    info: yup.array().of(yup.object(amountInnerRule)),
    note: yup.string().optional(),
    type: yup.number().oneOf([1, 2], ValidationMessage.required()).required(ValidationMessage.required()),
};

const baseValidator = {
    projectId: yup.string().required(ValidationMessage.required),
    specSheetId: yup.string().required(ValidationMessage.required),
    name: yup.string().required(ValidationMessage.required()),
    img: yup.array(imageValidator).optional(),
};

const packDataInfoValidator = yup.object({
    id: yup.string().required(ValidationMessage.required()),
    amountGroupId: yup.string().required(ValidationMessage.required()),
    sizeId: yup.string().required(ValidationMessage.required()),
    sizeName: yup.string().required(ValidationMessage.required()),
    quantity: yup.number().required(ValidationMessage.required()),
});

const packDataValidator = yup.object({
    id: yup.string().required(ValidationMessage.required()),
    info: yup.array(packDataInfoValidator).required(ValidationMessage.required()),
    totalQuantity: yup.number().optional().default(0),
});

export const createValidator = yup.object({
    ...baseValidator,
    isCreateNewGroup: yup.boolean().required(),
    groupId: yup.string().when('isCreateNewGroup', {
        is: (val: boolean) => val === false,
        then: (schema) => schema.required(ValidationMessage.required()),
        otherwise: (schema) => schema.optional(),
    }),
    group: createGroupValidator.when('isCreateNewGroup', {
        is: (val: boolean) => val === true,
        then: (schema) => schema.required(ValidationMessage.required()),
        otherwise: () => yup.object(),
    }),
    amountData: yup.array(yup.object(packingListAmountValidator)).required(ValidationMessage.required).min(1, 'ต้องเลือกอย่างน้อย 1 ไซซ์'),
});

export const updatePackDataValidator = yup.object({
    packData: yup.array(packDataValidator),
    id: yup.string().required(ValidationMessage.required()),
    totalQuantity: yup.number().optional().default(0),
});

export const updateValidator = yup.object({
    ...baseValidator,
    id: yup.string().required(),
    amountData: yup.array(yup.object(packingListAmountValidator)).required(ValidationMessage.required).min(1, 'ต้องเลือกอย่างน้อย 1 ไซซ์'),
    packData: yup.array(packDataValidator),
});
